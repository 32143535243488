import React from 'react';
import {FormattedMessage} from 'react-intl';

import CryingPicture from 'assets/image/contact-line/helpCry.svg';
import RunningPicture from 'assets/image/contact-line/helpRun.svg';
import TreePicture from 'assets/image/contact-line/helpTree.svg';

import './styles.scss';

type Props = {
  hideSeparator?: boolean;
}

export const ContactLine = ({hideSeparator}: Props): JSX.Element => {
  const separator = hideSeparator ? null : (<div className="contact-line__border"/>);

  return <div className="contact-line">
    {separator}
    <div className="container">
      <div className="contact-line__info">
        <img src={CryingPicture} className="contact-line__info__cry-picture" alt="" role="presentation"/>
        <div className="contact-line__info__numbers">
          <div className="contact-line__info__numbers__text-line">
            <div className="contact-line__info__text">
              <b className="contact-line__info__numbers__title">
                <FormattedMessage
                  id={'gritx.footer.contact.text'}
                  defaultMessage={'Text'}
                />
              </b>
              <FormattedMessage
                id={'gritx.footer.contact.textLine'}
                defaultMessage={'text line:'}
              />
            </div>
            <div className="contact-line__info__numbers__number">
              <FormattedMessage
                id={'gritx.footer.contact.numberText'}
                defaultMessage={'number'}
              />
            </div>
          </div>
          <div className="contact-line__info__numbers__life-line">
            <div className="contact-line__info__text">
              <b className="contact-line__info__numbers__title">
                <FormattedMessage
                  id={'gritx.footer.contact.call'}
                  defaultMessage={'Call'}
                />
              </b>
              <FormattedMessage
                id={'gritx.footer.contact.lifeline'}
                defaultMessage={'life line:'}
              />
            </div>
            <div className="contact-line__info__numbers__number">
              <FormattedMessage
                id={'gritx.footer.contact.numberLife'}
                defaultMessage={'number'}
              />
            </div>
          </div>
        </div>
        <section className="contact-line__info__pictures">
          <img src={RunningPicture} className="contact-line__info__pictures__run" alt="" role="presentation"/>
          <img src={TreePicture} className="contact-line__info__pictures__tree" alt="" role="presentation"/>
        </section>
      </div>
    </div>
    {/* <div className="contact-line__title">*/}
    {/*  <div className="container container__wrapper">*/}
    {/*    <div className="contact-line__title__text">*/}
    {/*      <FormattedMessage*/}
    {/*        id={'gritx.footer.contact.title'}*/}
    {/*        defaultMessage={'CONTACT US'}*/}
    {/*      />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/* </div>*/}
  </div>;
};

