import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {firstAuthorizedRoute, TranslationKeys, userAuthorizedRoute} from 'utils/constants/routeConstants';
import {proUser} from 'utils/constants/constants';
import {IStore} from 'redux/interface';
import {startLoginAction} from 'redux/auth/authAsyncActions';

import MeetYourTeam from 'components/meet-your-team';
import MainArea from 'components/main-area';

import './styles.scss';

interface appLocationState {
  from: Location;
}

export const Home: React.FunctionComponent = () => {
  const {
    auth: {
      auth0User,
      isFinishAuthorization
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();

  useGetTranslations({
    translationKey: TranslationKeys.Home
  });
  const {state: appLocState} = useLocation<appLocationState>();

  if (auth0User) {
    if (appLocState) {
      return <Redirect to={appLocState.from}/>;
    }
    const proFlag = auth0User?.['https://gritx.org/roles']?.includes(proUser);

    return <Redirect to={proFlag ? firstAuthorizedRoute : userAuthorizedRoute}/>;
  }
  if (!auth0User && appLocState && isFinishAuthorization) {
    if (appLocState) {
      dispatch(startLoginAction(appLocState.from.pathname + appLocState.from.hash));
    }

    return null;
  }

  return <div className="home">
    <h1 className="visually-hidden">
      <FormattedMessage
        id={'gritx.home.access.title'}
        defaultMessage={'Home page GritX website'}
      />
    </h1>
    <MainArea/>
  </div>;
};
